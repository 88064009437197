<template>
  <loading :active="isLoading" :can-cancel="true" :is-full-page="true" />

  <div class=" lg:w-3/4 mx-auto p-3">
    <h1 class="text-3xl mb-3">QA OTA by user</h1>
    <!-- <div class="lg:flex items-center mb-10">
      <label class="mr-3" for="email">Email address</label>
      <input
        type="text"
        class=" ring-gray-200 ring-1 p-1 mr-1"
        id="email"
        v-model="email"
        @keyup.enter="getUserDevice"
      />
      <input
        type="button"
        class="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
        value="search"
        @click="getUserDevice"
      />
    </div> -->

    <div class="text-sm">※ Please enter at least 3 letters</div>
    <div class="relative mb-4 flex flex-wrap items-stretch mx-auto">
      <input
        type="text"
        class="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-gray-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-blue-500 focus:text-gray-700 focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
        placeholder="Search User"
        v-model.trim="email"
        @keyup.enter="handleSubmit"
      />
      <button
        class="z-[2] inline-block rounded-r bg-blue-500 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:z-[3] focus:bg-blue-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
        @click="handleSubmit"
        type="button"
      >
        <BootstrapIcon icon="search" size="lg" />
      </button>
    </div>
    <button
      v-if="userDataList?.UserInfoList?.length"
      type="button"
      class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      @click="isShow = !isShow"
    >
      <svg
        class="w-5 h-5 ease-in duration-300"
        :class="{ 'rotate-90': !isShow }"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
      <span class="ml-1" v-if="!isShow"> 展開使用者列表</span>
      <span class="ml-1" v-else> 收起使用者列表</span>
      <span class="sr-only">Icon description</span>
    </button>
    <div
      class="userList my-4"
      :class="{ active: isShow }"
      v-if="userDataList?.UserInfoList?.length"
    >
      <div class="relative overflow-x-auto">
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Email</th>
              <th scope="col" class="px-6 py-3">Family Name</th>
              <th scope="col" class="px-6 py-3">Name</th>
              <th scope="col" class="px-6 py-3">UserID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white relative border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              v-for="(user, index) in userDataList.UserInfoList"
              :class="{
                'border-gray-300 border-b-2 border-dashed':
                  (index + 1) % 60 === 0,
              }"
              :key="user.Email"
            >
              <td
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                <a
                  class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  href="#"
                  @click.prevent="getTargetEmail(user)"
                  >{{ user.Email }}</a
                >
              </td>
              <td class="px-6 py-4">
                {{ user.FamilyName }}
              </td>
              <td class="px-6 py-4">
                {{ user.Name }}
              </td>
              <td class="px-6 py-4">
                {{ user.CognitoIdentityId }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="flex justify-center items-center text-gray-400">
          <button
            class="btn !flex flex-col justify-center items-center"
            type="button"
            @click="getMoreUser"
            v-if="this.userDataList.NextToken"
          >
            <span>See more</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="rgba(156, 163, 175)"
            >
              <polygon
                points="12 17.414 3.293 8.707 4.707 7.293 12 14.586 19.293 7.293 20.707 8.707 12 17.414"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div v-else>no data</div>

    <section v-if="DeviceData && DeviceData.length > 0">
      <div class="mb-4">
        <h3
          class="inline-block relative px-3 py-1  mb-3 after:block  after:w-full after:absolute after:top-[55%] after:left-0 after:border-b-[9px] after:border-b-[#69F0AE]"
        >
          <span>選擇裝置</span>
        </h3>
        <div class="mb-3">
          <!-- <label class="mr-3" for="fwid">請選擇Firmware Type</label> -->
          <select class="border p-2" v-model="FirmwareId">
            <option value="1">Aircon</option>
            <option value="2">Dehumidifier</option>
            <option value="3">Heatexchanger</option>
            <option value="4">PM25Panel</option>
          </select>
        </div>
        <ul v-if="TypeOfDevice.length > 0">
          <li
            class="flex items-center mb-2"
            v-for="item of TypeOfDevice"
            :key="item.CompId"
          >
            <input
              type="checkbox"
              class="w-5 h-5 mr-2"
              :value="item.CompID"
              :id="item.CompID"
              v-model="selectDevice"
            />
            <label :for="item.CompID"
              >{{ item.DeviceName }}
              <span
                v-if="mqttLogs[item.CompID.replace('dev#', '')]"
                class="text-sm text-yellow-600"
                >({{
                  `Online ${
                    mqttLogs[item.CompID.replace('dev#', '')].payload
                      .FirmwareVersion
                  }.${
                    mqttLogs[item.CompID.replace('dev#', '')].payload
                      .FirmwareCode
                  }`
                }})
                <div
                  class="relative h-4 border rounded-lg p-[2px] ease-linear duration-150"
                  v-if="mqttLogs[item.CompID.replace('dev#', '')].OTAProgress"
                >
                  <div
                    class=" relative h-full  bg-blue-400 rounded-lg text-center"
                    :style="[
                      mqttLogs[item.CompID.replace('dev#', '')].OTAProgress
                        ? `width:${
                            mqttLogs[item.CompID.replace('dev#', '')]
                              .OTAProgress
                          }%`
                        : 'width:0',
                    ]"
                  >
                    <span
                      class="absolute -right-10 -top-1/2 text-xs text-blue-600 "
                      >{{
                        mqttLogs[item.CompID.replace('dev#', '')].OTAProgress
                      }}%</span
                    >
                  </div>
                </div>
                <span
                  class="text-green-500"
                  v-if="mqttLogs[item.CompID.replace('dev#', '')].OTA === true"
                  >success!</span
                >
                <span
                  class="text-red-500"
                  v-else-if="
                    mqttLogs[item.CompID.replace('dev#', '')].OTA === false
                  "
                  >failed!</span
                >
                <span
                  v-else-if="
                    mqttLogs[item.CompID.replace('dev#', '')].OTA === 'ongoing'
                  "
                  >Not yet finished , please wait ...</span
                >
              </span>
              <span v-else class="text-sm text-gray-500"> (Offline)</span>
            </label>
          </li>
        </ul>
        <div v-else>尚無裝置</div>
      </div>
      <div class="mb-4">
        <label
          class="inline-block relative px-3 py-1  mb-3 after:block  after:w-full after:absolute after:top-[55%] after:left-0 after:border-b-[9px] after:border-b-[#69F0AE]"
          for="fw"
          >請輸入path或選擇較高的FW版本(擇一)</label
        >
        <input
          type="text"
          placeholder="fw-ota-test2.s3.ap-northeast-1.amazonaws.com"
          class="w-full p-1 block border mb-4 focus:outline-none focus:ring-1 focus:ring-yellow-500"
          v-model="targetOTAPath"
        />
        <hr
          class="relative my-4 border w-full h-px border-dashed after:content-['or'] after:block after:p-1 after:bg-gray-200  after:absolute after:left-0 after:top-0"
        />
        <div class="flex mb-3 border-b justify-between">
          <span class="w-10 mr-3"></span>
          <span class="flex-grow">Firmware Filename</span>
          <span class="w-[200px]">Firmware Version</span>
        </div>
        <div
          class="flex mb-3 justify-between"
          v-for="(item, index) of fwlists[FirmwareId]"
          :key="'fw' + index"
        >
          <div class="w-10 mr-3">
            <input
              class="w-4 h-4 "
              type="radio"
              name="FirmwareVersion"
              :id="'fw' + index"
              v-model="FirmwareVersion"
              :value="item.Key"
            />
          </div>
          <label class="flex-grow" :for="'fw' + index">{{ item.Key }}</label>
          <label class="w-[200px]" :for="'fw' + index">
            {{
              `${item.FirmwareVersion.Major}.${item.FirmwareVersion.Minor}.${item.FirmwareVersion.Patch}.${item.FirmwareVersion.Code}`
            }}
          </label>
        </div>
      </div>

      <div class="flex items-center justify-between my-6">
        <div>
          <button
            id="saveFile"
            class="relative ring ring-blue-400/70 p-2 rounded bg-blue-200/30  "
            @click.prevent="saveFile"
          >
            Select Firmware Filename & save
          </button>

          <label
            id="uploadFileLabel"
            for="uploadFile"
            class="mx-4 relative ring ring-red-400/70 p-2 rounded bg-red-200/30"
          >
            Upload File：<span v-if="this.firmwareObject">{{
              this.firmwareObject.name
            }}</span>
            <input
              id="uploadFile"
              hidden
              type="file"
              @change="fileChange"
              ref="fwFile"
            />
          </label>
        </div>

        <button
          class="border rounded-md px-2 py-1 ring ring-[#58cc94] bg-[#69F0AE] hover:bg-[#78ffbe] shadow-lg "
          @click.prevent="mqttOTAbyUserDevice"
        >
          submit
        </button>
      </div>
    </section>
    <div v-else>no device data</div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapActions, mapGetters } from 'vuex';
import AWSIoTData from 'aws-iot-device-sdk';
import cnf from '@/config';

import { saveAs } from 'file-saver';
import SparkMD5 from 'spark-md5';
import axios from 'axios';

// import https from '@/helpers/https'
// import { sleep } from '@/helpers'

export default {
  components: { Loading },
  data() {
    return {
      isShow: true,
      userData: null,
      email: '',
      userid: '',
      selectDevice: [],
      fwlists: [],
      mqttClient: null,
      mqttClientId:
        'GoogleHomeAdmin-DKIoTConsole' + Math.floor(Math.random() * 100000 + 1),
      mqttThingName: '',
      mqttPingElapseStartMs: 0,
      mqttPingElapseMs: 0,
      mqttTimeoutId: null,
      mqttResponseStatus: null,
      mqttLogs: {},
      sendOTAprocessing: false,
      FirmwareId: 1,
      FirmwareVersion: '',
      OTAstatus: false,
      OTAProgress: null,
      targetOTAPath: '',
      firmwareObject: null,
      contentMD5: null,
      bucketParams: { Bucket: 'BUCKET_NAME', Key: 'KEY' },
    };
  },
  created() {
    this.getFirwareObjects();
  },
  mounted() {
    // if (
    //   this.userPermissionsData &&
    //   this.userPermissionsData['Auth'] &&
    //   this.userPermissionsData['Auth'].includes('mqtt_credentials')
    // ) {
    // }
    this.getMqttCredentials();
    this.mqttConnect();
  },
  methods: {
    ...mapActions({
      searchUser: 'searchUser',
      getDevice: 'getDevice',
      getFirmwareIds: 'getFirmwareIds',
      listFirmwareObjects: 'listFirmwareObjects',
      getMqttCredentials: 'getMqttCredentials',
      getPresignUrl: 'getPresignUrl',
    }),
    saveFile() {
      if (this.FirmwareVersion.length > 0) {
        let object = this.fwlists[this.FirmwareId].filter(
          (item) => item.Key === this.FirmwareVersion
        );
        if (object) object = object[0].FirmwareVersion;

        let json = {
          Major: parseInt(object.Major),
          Minor: parseInt(object.Minor),
          Patch: parseInt(object.Patch),
          Code: parseInt(object.Code),
          Resource: '/' + this.FirmwareVersion,
          Host: 'hitachi-iot-core-hudson-test.s3.ap-northeast-1.amazonaws.com',
        };
        const blob = new Blob([JSON.stringify(json)], {
          type: 'application/json;charset=utf-8',
        });
        saveAs(blob, 'ota_qa.json');
      }
    },
    async fileChange(e) {
      this.firmwareObject = e.target.files[0];

      if (
        this.firmwareObject &&
        this.firmwareObject.name !== 'ota_main.json' &&
        this.firmwareObject.name !== 'ota_qa.json'
      ) {
        alert('檔案只能是ota_main.json或ota_qa.json!');
        this.firmwareObject = null;
      } else {
        this.contentMD5 = await this.createFileMd5(this.firmwareObject);

        this.upload();
        console.log(this.contentMD5);
      }
    },
    createFileMd5(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsText(file);
        fileReader.onload = (e) => {
          const result = e.target.result;
          const isSuccess = file.size === result.length;
          isSuccess
            ? resolve(SparkMD5.hash(result))
            : reject(new Error('read error'));
        };
        fileReader.onerror = () => reject(new Error('read error'));
      });
    },
    async upload() {
      if (!this.firmwareObject) {
        return alert('Please select file');
      }
      this.$store.dispatch('loading', true);
      await this.getPresignUrl({
        fileName: this.firmwareObject.name,
        firmwareId: parseInt(this.FirmwareId),
        contentMD5: this.contentMD5,
      });

      if (this.getFirmwarePresignedurlData && this.firmwareObject) {
        let response = await axios.put(
          this.getFirmwarePresignedurlData.PresignedUrl,
          this.firmwareObject,
          {
            headers: { 'Content-Type': 'application/octet-stream' },
          }
        );
        this.firmwareObject = null;
        this.contentMD5 = null;
        if (response.status === 200) {
          this.listFirmwareObjects(this.firmwareId);
          alert('Uploaded successfully!');
        }
      }
      this.$refs['fwFile'].value = null;
      this.$store.dispatch('loading', false);
    },
    async handleSubmit() {
      await this.searchUser({ email: this.email });
    },
    async getMoreUser() {
      const email = this.email;
      const token = this.userDataList.NextToken;
      if (email && token) {
        await this.searchUser({ email: email, next_token: token });
      }
    },
    getTargetEmail(data) {
      this.userData = { ...data };
      this.isShow = false;
    },
    async getUserDevice() {
      if (this.email.length === 0) return false;

      this.userid = this.userData.CognitoIdentityId;
      await this.getDevice(this.userData.CognitoIdentityId);
      // console.log(DeviceData)

      for (const item of this.DeviceData) {
        let MQTTthingname = item.CompID.replace('dev#', '');
        // console.log(MQTTthingname)
        this.mqttSubscribe(MQTTthingname);
        this.mqttCheckDeviceStatus(MQTTthingname);
      }
    },

    async getFirwareObjects() {
      // await this.getFirmwareIds()
      for (let i = 1; i <= 3; i++) {
        await this.listFirmwareObjects(i);
        this.fwlists[i] = this.firmwareObjects;
      }
      // console.log(this.fwlists)
    },
    mqttConnect() {
      const that = this;
      that.mqttClient = AWSIoTData.device({
        region: cnf.mqtt.region,
        host: cnf.mqtt.host,
        clientId: that.mqttClientId,
        protocol: 'wss',
        maximumReconnectTimeMs: 1800000,
        debug: false,
        accessKeyId: '',
        secretKey: '',
        sessionToken: '',
      });

      that.mqttClient.on('connect', function() {
        console.log('connect');
      });
      that.mqttClient.on('reconnect', function() {
        console.log('reconnect');
      });
      that.mqttClient.on('message', function(topic, payload) {
        const payload_obj = JSON.parse(payload);
        const ThingName = topic.split('/')[2];
        if (topic === that.mqttSubscribeTopicRegResp(ThingName)) {
          if (!that.mqttLogs[ThingName]) {
            that.mqttLogs[ThingName] = {
              topic: topic,
              ThingName: ThingName,
              time: new Date(),
              payload: payload_obj,
            };
          } else {
            let version = `${payload_obj.FirmwareVersion}.${payload_obj.FirmwareCode}`;
            version = version.replaceAll('.', '');
            // console.log(version)
            let oldversion = `${that.mqttLogs[ThingName].payload.FirmwareVersion}.${that.mqttLogs[ThingName].payload.FirmwareCode}`;
            oldversion = oldversion.replaceAll('.', '');
            // console.log(oldversion)
            let OTAstatus = false;
            if (version > oldversion) {
              OTAstatus = true;
              that.mqttLogs[ThingName] = {
                topic: topic,
                ThingName: ThingName,
                time: new Date(),
                payload: payload_obj,
                OTA: OTAstatus,
              };
            } else {
              that.mqttLogs[ThingName] = {
                ...that.mqttLogs[ThingName],
                OTA: OTAstatus,
              };
            }
          }
        } else if (topic == that.mqttSubscribeTopicOTAResp(ThingName)) {
          // that.mqttLogs[ThingName].payload.FirmwareVersion =
          //   'Please wait two minutes...'
          // that.mqttLogs[ThingName].payload.FirmwareCode = ''
          console.log(topic);
          console.log(payload_obj);
          if (payload_obj.Progress) {
            that.$store.commit('SET_LOADING', false);
            that.mqttLogs[ThingName] = {
              ...that.mqttLogs[ThingName],
              OTAProgress: payload_obj.Progress,
            };
            if (payload_obj.Progress === 100) {
              that.mqttLogs[ThingName].FirmwareVersion = '';
              that.mqttLogs[ThingName].FirmwareCode = '';
            }
          }
          // else {
          //   setTimeout(() => that.$store.commit('SET_LOADING', false), 20000)
          // }
          setTimeout(() => {
            that.mqttCheckDeviceStatus(ThingName);
          }, 5000);
        }

        // if (
        //   topic == that.mqttSubscribeTopicRegResp &&
        //   !payload_obj.Error &&
        //   payload_obj.FirmwareVersion
        // ) {
        //   that.mqttPingElapseMs = Date.now() - that.mqttPingElapseStartMs
        //   that.mqttResponseStatus = 'online'
        //   that.deviceData = {
        //     ...that.deviceData,
        //     FId: that.firmwareIdMapping[payload_obj.FirmwareId],
        //     FirmwareVersion: payload_obj.FirmwareVersion,
        //   }
        // }
      });
    },
    mqttCheckDeviceStatus(mqttThingName) {
      const that = this;
      // that.mqttResponseStatus = null
      // that.mqttPingElapseMs = 0
      // clearTimeout(that.mqttTimeoutId)

      // that.mqttPingElapseStartMs = Date.now()

      that.mqttClient.publish(
        that.mqttPublishTopicRegReq(mqttThingName),
        JSON.stringify({ Timestamp: Math.round(new Date() / 1000) })
      );
      this.$store.commit('SET_LOADING', false);
      // that.mqttTimeoutId = setTimeout(function() {
      //   if (that.mqttPingElapseMs === 0) {
      //     that.mqttResponseStatus = 'offline'
      //     that.deviceData = {
      //       ...that.deviceData,
      //       FId: 'offline',
      //       FirmwareVersion: 'offline',
      //     }
      //   }
      // }, 10000)
    },
    mqttOTAbyUserDevice() {
      this.$store.commit('SET_LOADING', true);
      const that = this;
      that.mqttResponseStatus = null;
      let path =
        this.targetOTAPath.length > 0
          ? this.targetOTAPath
          : 'hitachi-iot-core-hudson-test.s3.ap-northeast-1.amazonaws.com';
      if (path.length > 0) {
        for (const value of that.selectDevice) {
          console.log(path);
          console.log(that.mqttPublishTopicOTA(thingname));
          let thingname = value.replace('dev#', '');
          that.mqttLogs[thingname] = {
            ...that.mqttLogs[thingname],
            OTA: 'ongoing',
          };

          that.mqttClient.publish(
            that.mqttPublishTopicOTA(thingname),
            JSON.stringify({
              Timestamp: Math.round(new Date() / 1000),
              Ota: path,
              // Ota: 'hitachi-iot-core-hudson-test.s3.ap-northeast-1.amazonaws.com',
            })
          );
        }
      }
    },
    mqttSubscribe(mqttThingName) {
      const that = this;
      that.mqttResponseStatus = null;
      that.mqttThingName = mqttThingName;
      that.mqttClient.subscribe(that.mqttSubscribeTopic(mqttThingName));
      console.log('mqttSubscribe', that.mqttSubscribeTopic(mqttThingName));
    },
    mqttUnsubscribe() {
      const that = this;
      for (const item of that.DeviceData) {
        let thingname = item.CompID.replace('dev#');
        that.mqttClient.unsubscribe(that.mqttSubscribeTopic(thingname));
        console.log('mqttUnsubscribe', that.mqttSubscribeTopic(thingname));
      }
    },
    mqttPublishTopicRegReq(mqttThingName) {
      return `qa/${this.userid}/${mqttThingName}/registration/request`;
    },
    mqttPublishTopicOTA(mqttThingName) {
      return `qa/${this.userid}/${mqttThingName}/ota/request`;
    },
    mqttSubscribeTopicRegResp(mqttThingName) {
      return `qa/${this.userid}/${mqttThingName}/registration/response`;
    },
    mqttSubscribeTopicOTAResp(mqttThingName) {
      return `qa/${this.userid}/${mqttThingName}/ota/response`;
    },
    mqttSubscribeTopic(mqttThingName) {
      return `qa/${this.userid}/${mqttThingName}/+/response`;
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading',
      userDataList: 'getUserData',
      DeviceData: 'getDeviceData',
      firmwareObjects: 'getFirmwareObjectListData',
      mqttCredentialsData: 'getMqttCredentialsData',
      userPermissionsData: 'getUserPermissionsData',
      getFirmwarePresignedurlData: 'getFirmwarePresignedurlData',
    }),
    TypeOfDevice() {
      return this.DeviceData.filter(
        (item) => item.FirmwareId === this.FirmwareId * 1
      );
    },
  },
  watch: {
    mqttCredentialsData: function(val) {
      if (this.mqttClient) {
        this.mqttClient.updateWebSocketCredentials(
          val.AccessKeyId,
          val.SecretKey,
          val.SessionToken
        );
      } else {
        this.mqttConnect();
        this.mqttClient.updateWebSocketCredentials(
          val.AccessKeyId,
          val.SecretKey,
          val.SessionToken
        );
      }
    },
    deviceData: async function(val) {
      if (
        this.userPermissionsData &&
        this.userPermissionsData['Auth'] &&
        this.userPermissionsData['Auth'].includes('mqtt_credentials') &&
        val === null
      ) {
        clearTimeout(this.mqttTimeoutId);
        this.mqttUnsubscribe();
        this.mqttResponseStatus = null;
      }
    },
    userData: async function(val) {
      if (val) {
        this.getUserDevice();
      }
    },
  },
};
</script>

<style scoped>
h3:after,
label:after {
  z-index: -1;
}

#saveFile::after {
  content: 'Step 1';
  display: block;
  padding: 4px;
  background-color: aquamarine;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  position: absolute;
  left: -10px;
  top: -5px;
  transform: translateY(-50%) rotate(-10deg);
}

#uploadFileLabel::after {
  content: 'Step 2';
  display: block;
  padding: 4px;
  background-color: aquamarine;
  border-radius: 4px;
  font-size: 12px;
  width: fit-content;
  position: absolute;
  left: -10px;
  top: -5px;
  transform: translateY(-50%) rotate(-10deg);
  z-index: 2;
}

.userList {
  max-height: 0;
  transition: max-height 0.3s;
  overflow: hidden;
}
.userList.active {
  max-height: 500px;
  overflow: auto;
}
</style>
